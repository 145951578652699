import type { CSS as StitchesCSS, PropertyValue, ScaleValue } from '@stitches/react';
import { createStitches } from '@stitches/react';

import { breakpoints } from './common/breakpoints';
import { palette } from './common/colors';
import { fontFamilies, makeFontString } from './common/fonts';
import { gradients } from './common/gradients';
import { shadows as commonShadows } from './common/shadows';

const SCALE_BASE_PX = 16;

const rem = (px: number): string => `${px / SCALE_BASE_PX}rem`;

export const spacings = {
  0: rem(0),
  1: rem(1),
  2: rem(2),
  3: rem(3),
  4: rem(4),
  5: rem(5),
  6: rem(6),
  8: rem(8),
  10: rem(10),
  12: rem(12),
  14: rem(14),
  16: rem(16),
  18: rem(18),
  20: rem(20),
  22: rem(22),
  24: rem(24),
  28: rem(28),
  30: rem(30),
  32: rem(32),
  36: rem(36),
  40: rem(40),
  44: rem(44),
  48: rem(48),
  52: rem(52),
  56: rem(56),
  60: rem(60),
  64: rem(64),
  68: rem(68),
  72: rem(72),
  76: rem(76),
  80: rem(80),
  84: rem(84),
  88: rem(88),
  92: rem(92),
  96: rem(96),
  100: rem(100),
  104: rem(104),
  108: rem(108),
  112: rem(112),
  116: rem(116),
  120: rem(120),
  124: rem(124),
  128: rem(128),
  132: rem(132),
  136: rem(136),
  140: rem(140),
  144: rem(144),
  148: rem(148),
  152: rem(152),
  156: rem(156),
  160: rem(160),
  164: rem(164),
  168: rem(168),
  172: rem(172),
  176: rem(176),
  180: rem(180),
  184: rem(184),
  188: rem(188),
  192: rem(192),
  196: rem(196),
  200: rem(200),
  204: rem(204),
  208: rem(208),
  212: rem(212),
  216: rem(216),
  220: rem(220),
  224: rem(224),
  228: rem(228),
  232: rem(232),
  236: rem(236),
  240: rem(240),
  244: rem(244),
  248: rem(248),
  252: rem(252),
  256: rem(256),
  260: rem(260),
  264: rem(264),
  268: rem(268),
  272: rem(272),
  276: rem(276),
  280: rem(280),
  284: rem(284),
  288: rem(288),
  292: rem(292),
  296: rem(296),
  300: rem(300),
  304: rem(304),
  308: rem(308),
  312: rem(312),
  316: rem(316),
  320: rem(320),
  324: rem(324),
  328: rem(328),
  332: rem(332),
  336: rem(336),
  340: rem(340),
  344: rem(344),
  348: rem(348),
  352: rem(352),
  356: rem(356),
  360: rem(360),
  364: rem(364),
  368: rem(368),
  372: rem(372),
  376: rem(376),
  380: rem(380),
  384: rem(384),
  388: rem(388),
  392: rem(392),
  396: rem(396),
  400: rem(400),
  404: rem(404),
  408: rem(408),
  412: rem(412),
  416: rem(416),
  420: rem(420),
  424: rem(424),
  428: rem(428),
  432: rem(432),
  436: rem(436),
  440: rem(440),
  444: rem(444),
  448: rem(448),
  452: rem(452),
  456: rem(456),
  460: rem(460),
  464: rem(464),
  468: rem(468),
  472: rem(472),
  476: rem(476),
  480: rem(480),
  484: rem(484),
  488: rem(488),
  492: rem(492),
  496: rem(496),
  500: rem(500),
  504: rem(504),
  508: rem(508),
  512: rem(512),
  516: rem(516),
  520: rem(520),
  524: rem(524),
  528: rem(528),
  532: rem(532),
  536: rem(536),
  540: rem(540),
  544: rem(544),
  548: rem(548),
  552: rem(552),
  556: rem(556),
  560: rem(560),
  564: rem(564),
  568: rem(568),
  572: rem(572),
  576: rem(576),
  580: rem(580),
  584: rem(584),
  588: rem(588),
  592: rem(592),
  596: rem(596),
  600: rem(600),
  604: rem(604),
  608: rem(608),
  612: rem(612),
  616: rem(616),
  620: rem(620),
  624: rem(624),
  628: rem(628),
  632: rem(632),
  636: rem(636),
  640: rem(640),
  644: rem(644),
  648: rem(648),
  652: rem(652),
  656: rem(656),
  660: rem(660),
  664: rem(664),
  668: rem(668),
  672: rem(672),
  676: rem(676),
  680: rem(680),
  684: rem(684),
  688: rem(688),
  692: rem(692),
  696: rem(696),
  700: rem(700),
  704: rem(704),
  708: rem(708),
  712: rem(712),
  716: rem(716),
  720: rem(720),
};

export const theme = createStitches({
  theme: {
    colors: { ...palette, ...gradients },
    space: spacings,
    sizes: spacings,
    fonts: {
      mono: makeFontString(fontFamilies.mono),
      sans: makeFontString(fontFamilies.sans),
      title: makeFontString(fontFamilies.title),
    },
    shadows: { ...commonShadows },
    fontWeights: {
      light: '300',
      regular: '400',
      medium: '500',
      bold: '500',
      'extra-bold': '600',
    },
    radii: {
      0: rem(0),
      2: rem(2),
      4: rem(4),
      6: rem(6),
      8: rem(8),
      10: rem(10),
      12: rem(12),
      14: rem(14),
      16: rem(16),
      18: rem(18),
      20: rem(20),
    },
    lineHeights: {
      12: rem(12),
      16: rem(16),
      18: rem(18),
      20: rem(20),
      24: rem(24),
      28: rem(28),
      32: rem(32),
    },
    fontSizes: {
      9: rem(9),
      10: rem(10),
      11: rem(11),
      12: rem(12),
      13: rem(13),
      14: rem(14),
      16: rem(16),
      20: rem(20),
      24: rem(24),
    },
  },
  utils: {
    _transformParts: () => ({
      $$translateX: 0,
      $$translateY: 0,
      $$skewX: 0,
      $$skewY: 0,
      $$scaleX: 1,
      $$scaleY: 1,
      $$rotate: 0,
      transform:
        'translateX($$translateX) translateY($$translateY) rotate($$rotate) skewX($$skewX) skewY($$skewY) scaleX($$scaleX) scaleY($$scaleY)',
    }),
    translateY: (value: PropertyValue<'translate'>) => ({
      _transformParts: true,
      $$translateY: value,
    }),
    translateX: (value: PropertyValue<'translate'>) => ({
      _transformParts: true,
      $$translateX: value,
    }),
    paddingX: (value: PropertyValue<'paddingLeft'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    paddingY: (value: PropertyValue<'paddingTop'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    truncate: () => ({
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    borderRadiusTop: (value: PropertyValue<'borderTopLeftRadius'>) => ({
      borderTopLeftRadius: value,
      borderTopRightRadius: value,
    }),
    borderRadiusBottom: (value: PropertyValue<'borderTopLeftRadius'>) => ({
      borderBottomLeftRadius: value,
      borderBottomRightRadius: value,
    }),
    borderRadiusLeft: (value: PropertyValue<'borderTopLeftRadius'>) => ({
      borderTopLeftRadius: value,
      borderBottomLeftRadius: value,
    }),
    borderRadiusRight: (value: PropertyValue<'borderTopLeftRadius'>) => ({
      borderTopRightRadius: value,
      borderBottomRightRadius: value,
    }),
    hStack: (value: PropertyValue<'gap'>) => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: value,
    }),
    vStack: (value: PropertyValue<'gap'>) => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: value,
    }),
    strokeAll: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0 0 0.5px ${value}, inset 0 0 0 0.5px ${value}`,
    }),
    strokeTopBottom: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 ${value}, inset 0 -0.5px 0 ${value}, 0 0.5px 0 ${value}, inset 0 0.5px 0 ${value}`,
    }),
    strokeTop: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}`,
    }),
    strokeBottom: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}`,
    }),
    strokeLeftRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `-0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeLeft: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `-0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeTopLeft: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeTopRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeBottomLeft: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeBottomRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeNoTop: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeNoRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, 0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeNoBottom: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeNoLeft: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, 0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
  },
  media: {
    sm: `(min-width: ${breakpoints.sm}px)`,
    md: `(min-width: ${breakpoints.md}px)`,
    lg: `(min-width: ${breakpoints.lg}px)`,
    xl: `(min-width: ${breakpoints.xl}px)`,
    maxSm: `(max-width: ${breakpoints.sm - 1}px)`,
    maxMd: `(max-width: ${breakpoints.md - 1}px)`,
    maxLg: `(max-width: ${breakpoints.lg - 1}px)`,
    maxXl: `(max-width: ${breakpoints.xl - 1}px)`,
    // Named presets
    mobile: `(max-width: ${breakpoints.sm}px)`,
    notMobile: `(min-width: ${breakpoints.sm + 1}px)`,
    tablet: `(min-width: ${breakpoints.sm + 1}px) and (max-width: ${breakpoints.lg - 1}px)`,
    notDesktop: `(max-width: ${breakpoints.lg - 1}px)`,
    desktop: `(min-width: ${breakpoints.lg}px)`,
  },
});

export const {
  styled,
  css,
  keyframes,
  globalCss,
  theme: { fonts, shadows, colors, fontWeights },
} = theme;

export type CSS = StitchesCSS<typeof theme.config>;

export const darkThemeSelector = '.m-dark &';

export const activeThemeClassName = 'atto-active-theme';
export const activeThemeSelector = `.${activeThemeClassName} &`;

export const focusVisibleClassName = 'atto-focus-visible';
export const focusVisibleSelector = `.${focusVisibleClassName} &`;

export const safariOnlyCSS = (properties: CSS) => ({
  '@media not all and (min-resolution:.001dpcm)': {
    '@supports (-webkit-appearance:none)': {
      '&': properties,
    },
  },
});

export const firefoxOnlyCSS = (properties: CSS) => ({
  '@-moz-document url-prefix()': {
    '&': properties,
  },
});
